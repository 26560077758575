import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'content']
  static values = {
    activeTab: {
      type: String,
      default: 'plan-1',
    },
  }

  connect() {
    this.refreshActiveTab(this.activeTabValue)
  }

  activateTab(e) {
    const activeId = e.currentTarget.dataset.tabId
    this.refreshActiveTab(activeId)
    this._broadcast(activeId, `${activeId}-content`)
  }

  refreshActiveTab(activeId) {
    for (const tab of this.tabTargets) {
      const isActive = tab.dataset.tabId === activeId
      this._updateStateOfTabs(tab.dataset.tabId, isActive)
      this._updateStateOfContents(`${tab.dataset.tabId}-content`, isActive)
    }
  }

  _activate(tab) {
    tab.classList.remove('font-light', 'text-blueGray-700', 'bg-transparent')
    tab.classList.add('font-bold', 'text-white', 'bg-green-500')
  }

  _deactivate(tab) {
    tab.classList.remove('font-bold', 'text-white', 'bg-green-500')
    tab.classList.add('font-light', 'text-blueGray-700', 'bg-transparent')
  }

  _updateStateOfTabs(tabSelector, isActive) {
    const tabs = document.querySelectorAll(`[data-tab-id='${tabSelector}']`)
    if (!tabs) return

    for (const tab of tabs) {
      isActive ? this._activate(tab) : this._deactivate(tab)
    }
  }

  _updateStateOfContents(contentSelector, isActive) {
    const contents = document.querySelectorAll(
      `[data-tab-id='${contentSelector}']`
    )
    if (!contents) return

    for (const content of contents) {
      isActive
        ? content.classList.remove('hidden')
        : content.classList.add('hidden')
    }
  }

  _broadcast(activeTabId, activeTabContentId) {
    document.dispatchEvent(
      new CustomEvent('plan-tabs-switched', {
        detail: {
          activeTabId,
          activeTabContentId,
        },
      })
    )
  }
}
